import React, { useState } from "react"
import styled from "styled-components"
import ImgModal from "../imagemodal"
import Franchise1 from "../../static/franchise/franchise1.png"
import Franchise2 from "../../static/franchise/franchise2.png"
import Modal from "react-bootstrap/Modal"
import popup_content from "../../static/popup-img.jpg"
import Popup_ans from "../../pages/popup-answer"
import "../../style/modal.css"
import LeftArrow from "../../static/arrow(l)3.svg"
import RightArrow from "../../static/arrow(R)3.svg"
import Slider from "react-slick"

const Container = styled.div`
  .franchise1-img-part1 img {
    width: 80vw;
    display: block;
    margin: 0 auto;
  }

  .franchise1-text-part1 {
    position: absolute;
    width: 50vw;
    left: 50vw;
    margin-left: -25vw;
    margin-top: 7vw;
  }

  .franchise1-text-part1 p:first-child {
    font-size: 1.7vw;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
  }

  .franchise1-text-part1 p:last-child {
    font-size: 4vw;
    text-align: center;
    font-weight: 300;
    line-height: 1.49;
  }

  .franchise1-text-part1 p span {
    font-weight: bold;
  }

  .promotion {
    width: 100vw;
    background-color: rgba(203, 48, 30, 0.61);
    position: absolute;
    text-align: center;
    padding: 3vw 0;
    margin-top: 21vw;
  }

  .promotion p {
    color: white;
  }

  .promotion p:first-child {
    font-size: 3.5vw;
    font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans JP', sans-serif;
    font-weight: 900;
  }

  .promotion p:nth-child(2) {
    font-size: 1vw;
    margin: 2.5vw 0;
  }

  .promotion button {
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 100px;
    background-color: white;
    box-shadow: 0px 0px 8px #9e5a5a;
    font-size: 1vw;
    font-weight: bold;
    padding: 1.7vw 2.5vw 1.2vw;
  }

  .popup_img img {
    width: 50vw;
    display: block;
  }

  .popup_btn {
    width: 15vw;
    position: absolute;
    bottom: 0;
    margin: 0 0 3vw -7.5vw;
    display: block;
    border: none;
    left: 25vw;
    padding: 1.2vw 0 1vw;
    border-radius: 100px;
    background-color: #fff;
    font-weight: bold;
  }

  .franchise1-img-part1 img{
    width: 80%;
    display: block;
  }

  .main8-slide-source iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .main8-slide-source {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }

  .slick-slide div {
    margin: 0 auto;
    width: 55vw;
  }

  .slick-prev {
    left: 19vw;
    z-index: 99;
  }

  .slick-next {
    right: 19vw;
    z-index: 99;
  }

  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  .main8-media-slide {
    margin-top: -1vw;
    padding: 5vw 0 10vw;
    position: relative;
  }

  .main8-media-slide p {
    text-align: center;
    font-size: 2.7vw;
    font-weight: bold;
    margin:2vw 0 6vw;;
  }

  }
  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  .slick-dots {
    display: none !important;
  }

  @media screen and (max-width: 480px) {

    .franchise1-img-part1 img{
      width: 100%;
    }

    .slick-slide div {
      margin: 0 auto;
      width: 80vw;
    }

    .slick-prev {
      left: 2vw;
      z-index: 99;
    }
  
    .slick-next {
      right: 2vw;
      z-index: 99;
    }


    .main8-media-slide p {
      font-size: 5vw;
      margin: 4vw 0 7vw;
   
    }
  
  }
  `

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          fontSize: "2vw",
          zIndex: 99,
          color: "#fff",
        }}
        onClick={onClick}
      >
        <img style={{ width: "2vw" }} src={RightArrow} alt="화살표" />
      </div>
    )
  }
  
  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          zIndex: 99,
          color: "#fff",
        }}
        onClick={onClick}
      >
        <img
          style={{ width: "2vw", float: "right" }}
          src={LeftArrow}
          alt="화살표"
        />
      </div>
    )
  }
  
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: true,
  }

  
const frenchise1 = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const openModal = () => {
    setModalVisible(true)
  }
  const closeModal = () => {
    setModalVisible(false)
  }

  const nextModal = () => {
    setModalVisible(false)
    handleShow()
  }

  var now = new Date() // 현재 날짜 및 시간
  var month = now.getMonth()

  return (
    <Container>
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
           />
           <link
           rel="stylesheet"
           type="text/css"
           href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
           />
  
       <div className="franchise1-img-part1">
        <img src={Franchise1} />
      </div>

      <div className="main8-media-slide">
        <p>먼저 창업해 본 사람들은 압니다!</p>
        <Slider {...settings}>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/1Y1lypYny04?rel=0"></iframe>
          </div>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/LPDhxvSJG2s?rel=0"></iframe>
          </div>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/_wPRXIVP8m4?rel=0"></iframe>
          </div>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/rG7X5TkzjOc?rel=0"></iframe>
          </div>
        </Slider>
      </div>

      <div className="franchise1-img-part1">
        <img src={Franchise2} />
      </div>


      {/* <div className="promotion">
        <p>{month + 1}월 창업프로모션 마감 임박!</p>
        <p>
          지금 바로 무료 상담 받고 신규가맹/업종변경 특별 창업 혜택을
          받아보세요.
        </p>
        <button onClick={openModal}>자세히 보기</button>
      </div>
      {modalVisible && (
        <ImgModal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
        >
          <button className="popup_btn" onClick={nextModal}>
            문의하기
          </button>
          <div className="popup_img">
            <img src={popup_content} />
          </div>
        </ImgModal>
      )} */}

      <Modal className="answer-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <Popup_ans />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default frenchise1
