import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import ImgModal from "./statement"
import swal from "sweetalert"

const Container = styled.div`
  padding 3vw 0 6vw;
  background-color: #f0f0f0;
  .franchise1-input-part {
    width: 60vw;
    margin: 0 auto;
  }

  .franchise1-input-part input {
    width: 60vw;
    height: 3vw;
    border: none;
    padding: 2vw 1vw 1.5vw;
    font-size:1.5vw;
  }

  .franchise1-input-part p {
    font-size: 1.8vw;
    padding: 3vw 0 1vw;
    font-weight: bold;
  }

  .mini-select {
    width: 2vw !important;
    height: 1vw !important;
  }

  textarea {
    width: 60vw;
    height: 8vw;
    outline:none;
    border: none;
    resize: none;
  }

  .agree-check-box {
    margin-top: 3vw;
  }

  .agree-check-box span {
    font-weight:bold;
  }

  .franchise-apply-btn {
    display: block;
    margin: 3vw auto 0;
    border: none;
    border-radius: 100px;
    background-color: #CC5541;
    color: #fff;
    padding: 1.5vw 5vw 1.3vw;
    font-size: 1vw;
  }

  .franchise1-input-part label {
    font-size: 1.4vw;
  }

  .checkbox-all {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width:480px) {
    .franchise1-input-part {
      width: 80vw;
    }

    .franchise1-input-part p {
      font-size: 4vw;
      padding: 5vw 0 2vw;
    }

    .franchise1-input-part input {
      width: 80vw;
      height: 7vw;
      font-size: 3.5vw;
  }

  textarea {
    width: 80vw;
    height: 30vw;
    font-size: 3.5vw;
  }

  .franchise1-input-part label {
    font-size: 3vw;
}

.agree-check-box {
  margin: 7vw 0 5vw
}

.mini-select {
  width: 6vw !important;
  height: 3vw !important;
}

.franchise-apply-btn {
  padding: 2.5vw 7vw 2.3vw;
  font-size: 4vw;
}
}
`

const frenchise2 = () => {
  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
  }
  const closeModal = () => {
    setModalVisible(false)
  }
  const [data, setData] = useState({
    name: "",
    phone: "010-",
    address: "",
    inquire: "",
    route: "웹(샘카페)",
  })

  const [check, setCheck] = useState(false)
  const { name, phone, address, inquire } = data
  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  async function sendEmail() {
    try {
      await axios.post("https://api.samoffice.co.kr/mailSend", data, config)
    } catch (err) {
      console.log(err)
    }
  }
  const token = "test"
  let config = {
    headers: {
      "X-JWT-TOKEN": token,
    },
  }

  const [typeset, setType] = useState(0)
  const sendMess = async () => {
    var info = Object.assign({}, data, {
      select: typeset,
    })
    try {
      await axios.post("https://api.letmeup.co.kr/api/signSMS", info, config)
    } catch (err) {
      console.log(err)
    }
  }

  //엔터중복 방지
  let submitFlag = false
  const doubleSubmitCheck = () => {
    if (submitFlag) {
      return submitFlag
    } else {
      submitFlag = true
      return false
    }
  }

  //nocodeapi를 이용한 구글 sheet에 내용 삽입
  //nocodeapi 계정 권동준
  //sheet문서 가맹문의_웹_전화

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      var info = Object.assign({}, data, {
        select: typeset,
      })
      if (
        info.address == "" ||
        info.name == "" ||
        info.inquire == "" ||
        info.phone == "010-"
      ) {
        swal("문의사항 목록을 채워주세요")
      } else if (check == false) {
        swal("개인정보 취급방침을 체크해주세요")
      } else {
        if (doubleSubmitCheck()) return

        let response = await axios.post(
          "https://api.samoffice.co.kr/signup",
          info,
          config
        )

        if (response.status == 200) {
          sendEmail()
          sendMess()
          setData({
            ...data,
            name: "",
            phone: "010-",
            address: "",
            inquire: "",
          })
          swal("신청되었습니다")
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <div className="franchise1-input-part">
        <p>이름</p>
        <input
          type="text"
          placeholder="이름"
          name="name"
          value={name}
          onChange={handleChange}
        />
        <p>연락처</p>
        <input
          type="text"
          placeholder="연락처"
          name="phone"
          value={phone}
          onChange={handleChange}
        />
        {/* <p>왜 공간샘을 선택하셨나요?</p>
        <div className="checkbox-all">
          <div>
            <input
              className="mini-select"
              type="checkbox"
              name="type"
              value="관리형 스터디카페"
              onChange={({ target }) => setType(target.value)}
            />
            <label>관리형 스터디카페</label>
          </div>
          <div>
            <input
              className="mini-select"
              type="checkbox"
              name="type"
              value="무인운영"
              onChange={({ target }) => setType(target.value)}
            />
            <label>무인운영</label>
          </div>
          <div>
            <input
              className="mini-select"
              type="checkbox"
              name="type"
              value="기술력"
              onChange={({ target }) => setType(target.value)}
            />
            <label>기술력</label>
          </div>
          <div>
            <input
              className="mini-select"
              type="checkbox"
              name="type"
              value="인테리어"
              onChange={({ target }) => setType(target.value)}
            />
            <label>인테리어</label>
          </div>
          <div>
            <input
              className="mini-select"
              type="checkbox"
              name="type"
              value="기타"
              onChange={({ target }) => setType(target.value)}
            />
            <label>기타</label>
          </div>
        </div>
        */}
        <p>희망지역</p>
        <input
          type="text"
          placeholder="희망지역"
          name="address"
          value={address}
          onChange={handleChange}
        />
        <p>문의내용</p>
        <textarea name="inquire" value={inquire} onChange={handleChange} />
        <br />
        <label className="agree-check-box">
          <span onClick={openModal}>개인정보취급방침</span>을 읽었으며 이에
          동의합니다
        </label>
        <input
          className="mini-select"
          type="checkbox"
          value={check}
          onChange={setCheck}
        />
      </div>
      <button onClick={handleSubmit} className="franchise-apply-btn">
        신청하기
      </button>

      {modalVisible && (
        <ImgModal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
        ></ImgModal>
      )}
    </Container>
  )
}
export default frenchise2
