import React from "react"
import Layout from "../components/Layout/Layout"
import styled from "styled-components"

import Franchise1 from "../components/franchise/franchise1"
import Franchise2 from "../components/franchise/franchise2"
const Container = styled.div`
  overflow-x: hidden;
`
const franchisePage = () => {
  return (
    <Container>
      <Layout>
        <Franchise1 />
        <Franchise2 />
      </Layout>
    </Container>
  )
}

export default franchisePage
